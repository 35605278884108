import { StaticQuery, graphql } from "gatsby";
import React from "react";
import emVideo from "../mp4/e2-comp.hevc.mp4";
import emVideoBackup from "../mp4/backups/e2-comp.mp4";
import DevicePageTemplate from "components/common/templates/DevicePageTemplate";

export default function EnvironmentSensorsPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "environmental monitoring",
        title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
        description:
          "Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video. ",
        image: data.headerImage,
        button1: { text: "View Pricing", path: "/pricing/sensors" },
        button2: { text: "Start Trial", path: "/free-trial" },
      },
      cardSection: {
        title: "Explore Environmental Sensors",
        cards: [
          {
            image: data.device1,
            name: "E2",
            description: "Monitor air quality, vape, TVOCs, and more.",
            link: "/sensors/environmental-monitoring/e2/",
            flex: 1.5,
          },
          {
            image: data.device2,
            name: "E15",
            description: "Monitor temperature and humidity.",
            link: "/sensors/environmental-monitoring/e15/",
            flex: 3,
          },
        ],
      },
      benefitsSection: {
        title: "Get Real-Time Environmental Insights Into Your Spaces",
        items: [
          {
            image: data.benefit1,
            title: "Protect Climate-Controlled Assets",
            description:
              "Monitor temperature and humidity in real time and detect fluctuations before damage occurs. Prevent spoilage, boost quality control, and identify potential equipment failure. ",
          },
          {
            image: data.benefit2,
            title: "Detect Vape & Smoke",
            description:
              "Reliably detect vape and smoke particles via chemical signature. Pair real-time sensor data with video evidence to take restorative action with confidence. ",
          },
          {
            image: data.benefit3,
            title: "Create Healthier Spaces",
            description:
              "Continuously monitor indoor air quality to protect employees and guests from harmful airborne chemicals. Proactively detect TVOCs, CO2 levels, and more.",
          },
        ],
      },
      specSection: {
        title: "Capture More than Video",
        image: data.specImage,
        specs: [
          { icon: data.icon1, title: "Temperature", subTitle: "Monitoring" },
          { icon: data.icon2, title: "Humidity", subTitle: "Measuring" },
          {
            icon: data.icon3,
            title: "Indoor Air Quality",
            subTitle: "Measuring",
          },
          { icon: data.icon4, title: "Smoke", subTitle: "Detection" },
          { icon: data.icon5, title: "Vape", subTitle: "Detection" },
          {
            icon: data.icon6,
            title: "Particulate Matter",
            subTitle: "Detection",
          },
        ],
      },
    };
    return (
      <>
        <DevicePageTemplate
          data={pageData}
          video={emVideo}
          videoBackup={emVideoBackup}
          cameras={false}
        />
      </>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/enviro-sensor-hero-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      device2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e15-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e15-temperature-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/e2-ceiling-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/a100-full-environment-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      specImage: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/updated/img/spec-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/temperature.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/humidity.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/air.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/cigarette.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon5: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/vape.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon6: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/particles.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bladeImageMobile: file(
        relativePath: {
          eq: "components/sensors/overview/img/sensor-trial-banner-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
