import GlobalLayout from "components/page/GlobalLayout";
import EnvironmentSensorsPage from "components/sensors/environment-monitoring/updated/EnvironmentSensorsPage";
import React from "react";
import Helmet from "react-helmet";

export default function EnvironmentalMonitoring() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Rhombus Environmental Sensors - Vape, Smoke, Air Quality, & More
        </title>
        <meta
          name="description"
          content="Learn about Rhombus' enterprise-grade environmental sensors for commercial businesses, schools, healthcare, real estate, and more."
        />
      </Helmet>
      <EnvironmentSensorsPage />
    </GlobalLayout>
  );
}
